import React from 'react';
import styled from 'styled-components';
import {Size} from "../props/common";
import {grey75} from "../config/style";

interface DividerProps {
    margin?: Size;
    size?: string;
    children?: any;
    direction?: 'horizontal' | 'vertical';
    color?: string;
}

const DividerStyled = styled.button<DividerProps>`
  width: ${props => props.direction === 'horizontal' ? props.size : 0};
  height: ${props => props.direction === 'horizontal' ? 0 : props.size};
  border: 1px solid ${props => props.color};
  margin: ${props => props.direction === 'horizontal' ? `${props.margin}px 0` : `0 ${props.margin}px`};
  box-sizing: border-box;
`;

const Divider = ({
                     margin = Size.LEVEL_THREE,
                     children,
                     direction = 'horizontal',
                     size = `${Size.LEVEL_THREE}px`,
                     color = grey75
                 }: DividerProps) => {
    return <DividerStyled margin={margin} size={size} direction={direction} color={color}>
        {children}
    </DividerStyled>;
};

export default Divider;
