import React, {useEffect, useState} from "react";
import Container from "../../components/Container";
import Title from "../../components/Title";
import {fetchAPI} from "../../config/fetch";
import {_sortMatchesByTableNumber, TeamMatchProps} from "../match/matchProps";
import Table from "../../components/table/Table";
import TableHead from "../../components/table/TableHead";
import TableBody from "../../components/table/TableBody";
import Flex, {FlexItem} from "../../components/Flex";
import Button from "../../components/Button";
import {Size} from "../../props/common";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../config/env";
import DashboardLayout from "../../components/DashboardLayout";
import Input from "../../components/Input";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ["websocket"]});
const TeamDashboard = () => {
    const [currentRound, setCurrentRound] = useState(0);
    const [updatedRound, setUpdatedRound] = useState(currentRound);
    const [matches, setMatches] = useState([]);
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);

    useEffect(() => {
        socket.on("updated-scores", (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });

        fetchAPI(`/current-round-team/CdF2024`)
            .then(res => {
                setCurrentRound(res.current_round);
            });
    }, []);

    useEffect(() => {
        fetchAPI(`/matches/CdF2024/team/rounds`)
            .then(res => {
                // @ts-ignore
                setMatches(_sortMatchesByTableNumber(res));
                setReadyToUpdate(false);
            });
    }, [readyToUpdate, currentRound]);

    const _updateCurrentRound = () => {
        setCurrentRound(updatedRound);

        fetchAPI(`/current-round-team/CdF2024`, "POST", {current_round: updatedRound})
            .catch(err => {
                console.log(err.errMsg);
                alert("Error while updating round");
            });
        socket.emit("update-current-round", updatedRound);
    };

    return (
        <DashboardLayout>
            <Container padding={Size.LEVEL_TWO}>
                <Flex alignItems="center" justifyContent="space-between" mobile>
                    <FlexItem minWidth="200px" width="auto">
                        <Title marginBottom={0} size={20}>Série actuelle :</Title>
                    </FlexItem>
                    <FlexItem minWidth="150px" width="auto">
                        {
                            currentRound > 0 && <Input
                                type="number"
                                placeholder="Update round..."
                                defaultValue={currentRound}
                                onChange={(event) => setUpdatedRound(event.target.value)}
                                marginLess
                                min={1}
                                max={100}
                            />
                        }
                    </FlexItem>
                    <FlexItem><Button onClick={_updateCurrentRound}>{`ᐅ`}</Button></FlexItem>
                </Flex>
            </Container>
            <Container padding={Size.LEVEL_TWO}>
                {
                    matches.length ?
                        <Table rounded>
                            <TableHead>
                                <tr>
                                    <td width="10%">Tables</td>
                                    <td width="25%">Player 1</td>
                                    <td width="15%">Score</td>
                                    <td width="25%">Player 2</td>
                                    <td width="25%">Referee</td>
                                </tr>
                            </TableHead>
                            <TableBody>
                                {
                                    matches.map((match: TeamMatchProps, index) => {
                                        return <tr key={index}>
                                            <td>{match.table_number} - {match.table_number + 3}</td>
                                            <td>{match.team_one.name}</td>
                                            <td>{match.score_team_one} - {match.score_team_two}</td>
                                            <td>{match.team_two.name}</td>
                                            <td>{match.referee}</td>
                                        </tr>;
                                    })
                                }
                            </TableBody>
                        </Table>
                        : <Title>No matches found for round {currentRound}</Title>
                }
            </Container>
        </DashboardLayout>
    );
};

export default TeamDashboard;
