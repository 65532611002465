import React from 'react';
import styled from 'styled-components';
import {red} from "../../config/style";

export interface TableHeadPros {
    children?: React.ReactElement
}

const TableHeadStyled = styled.thead<TableHeadPros>`
  width: 100%;
  background-color: ${red};
  color: white;
  font-weight: bold;
`;

const TableHead = ({children}: TableHeadPros) => {
    return <TableHeadStyled>
        {children}
    </TableHeadStyled>
}

export default TableHead;