import React from 'react';
import styled from 'styled-components';
import {OptionProps} from "./Option";
import {grey150, grey75} from "../../config/style";

interface SelectProps {
    children: React.ReactElement<OptionProps> | React.ReactElement<OptionProps>[] | any
    id?: string
    defaultValue?: string
    onChange?: any
    borderRadius?: string
    disabled?: boolean
}

const SelectWrapper = styled.div<{ id?: string, borderRadius?: string }>`
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  border-radius: ${props => props.borderRadius};
  cursor: pointer;
  font-family: 'InterFont', serif;
  width: 100%;
  height: 100%;
  border: 2px solid ${grey75};

  select {
    font-family: 'InterFont', serif;
    font-weight: normal;
    width: 100%;
    height: 100%;
    padding: 8px 24px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    font-size: 24px;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 24px;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-top: 8px solid ${grey150};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;

export const Select = ({children, id, defaultValue, onChange, borderRadius = "0px", disabled = false}: SelectProps) => {
    return <SelectWrapper id={id} borderRadius={borderRadius}>
        <select defaultValue={defaultValue} onChange={onChange} disabled={disabled}>
            {children}
        </select>
    </SelectWrapper>;
}

export default Select