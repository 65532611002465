import env from "./env";

export const fetchAPI = async (url: string, method: string = "GET", body: any = {}, file: boolean = false) => {
    let headers;
    file ? headers = {'Accept': "*/*"} : headers = {'Accept': "application/json", 'Content-Type': "application/json"};

    const postQueryConf: any = {
        method: 'POST',
        headers: headers,
        body: file ? body : JSON.stringify(body)
    }

    switch (method) {
        case 'GET':
            return await fetch(env.API + url)
                .then(res => res.json())
                .then(res => res)
                .catch(err => err);
        case 'POST':
            return await fetch(env.API + url, postQueryConf)
                .then(res => res.json())
                .then(res => res)
                .catch(err => err);
        default:
            break;
    }
}