import React, {useEffect, useState} from "react";
import Container from "../../components/Container";
import Title from "../../components/Title";
import {fetchAPI} from "../../config/fetch";
import {_sortMatchesByTableNumber, MatchProps} from "../match/matchProps";
import Table from "../../components/table/Table";
import TableHead from "../../components/table/TableHead";
import TableBody from "../../components/table/TableBody";
import Flex, {FlexItem} from "../../components/Flex";
import Button from "../../components/Button";
import {Size} from "../../props/common";
import Spacer from "../../components/Spacer";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../config/env";
import DashboardLayout from "../../components/DashboardLayout";
import Input from "../../components/Input";
import IndividualGroups from "../groups/Individual";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ["websocket"]});
const IndividualDashboard = () => {
    const [currentRound, setCurrentRound] = useState(0);
    const [updatedRound, setUpdatedRound] = useState(1);
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        fetchAPI(`/current-round/CdF2024`)
            .then(res => {
                setCurrentRound(res.current_round);
                fetchAPI(`/matches/CdF2024/individual/rounds`)
                    .then(res => {
                        // @ts-ignore
                        setMatches(_sortMatchesByTableNumber(res));
                    });
            });
    }, []);

    const _updateCurrentRound = () => {
        fetchAPI(`/current-round/CdF2024`, "POST", {current_round: updatedRound})
            .then(() => {
                socket.emit("update-current-round", updatedRound);
                window.location.reload();
            })
            .catch(err => {
                console.log(err.errMsg);
                alert("Error while updating round");
            });
    };

    return (
        <DashboardLayout>
            <Container padding={Size.LEVEL_TWO}>
                <Flex alignItems="center" justifyContent="space-between" mobile>
                    <FlexItem minWidth="200px" width="auto">
                        <Title marginBottom={0} size={20}>Série actuelle :</Title>
                    </FlexItem>
                    <FlexItem minWidth="150px" width="auto">
                        {
                            currentRound > 0 && <Input
                                type="number"
                                placeholder="Update round..."
                                defaultValue={currentRound}
                                onChange={(event) => setUpdatedRound(event.target.value)}
                                marginLess
                                min={1}
                                max={100}
                            />
                        }
                    </FlexItem>
                    <FlexItem><Button onClick={_updateCurrentRound}>{`ᐅ`}</Button></FlexItem>
                </Flex>
            </Container>
            <Container padding={Size.LEVEL_TWO}>
                {
                    matches.length ?
                        <Table rounded>
                            <TableHead>
                                <tr>
                                    <td width="10%">Table</td>
                                    <td width="25%">Player 1</td>
                                    <td width="15%">Score</td>
                                    <td width="25%">Player 2</td>
                                    <td width="25%">Referee</td>
                                </tr>
                            </TableHead>
                            <TableBody>
                                {
                                    matches.map((match: MatchProps, index) => {
                                        return <tr key={index}>
                                            <td>{match.table_number}</td>
                                            <td>{match.player_one.fullname}</td>
                                            <td>{match.score_player_one} - {match.score_player_two}</td>
                                            <td>{match.player_two.fullname}</td>
                                            <td>{match.referee}</td>
                                        </tr>;
                                    })
                                }
                            </TableBody>
                        </Table>
                        : <Title>No matches found for round {currentRound}</Title>
                }
            </Container>
            <Spacer/>
            <Container padding={Size.LEVEL_TWO}>
                <Title marginBottom={0}>Divisions</Title>
            </Container>
            <IndividualGroups withoutHead/>
        </DashboardLayout>
    );
};

export default IndividualDashboard;
