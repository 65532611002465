import React, {useEffect, useState} from "react";
import {fetchAPI} from "../../config/fetch";
import DashboardLayout from "../../components/DashboardLayout";
import {useParams} from "react-router-dom";
import TableHead from "../../components/table/TableHead";
import Title from "../../components/Title";
import {Size} from "../../props/common";
import TableBody from "../../components/table/TableBody";
import Table from "../../components/table/Table";
import Container from "../../components/Container";
import AccordionItem from "../../components/accordion/AccordionItem";
import AccordionHeader from "../../components/accordion/AccordionHeader";
import AccordionBody from "../../components/accordion/AccordionBody";
import {MatchProps} from "../match/matchProps";
import Accordion from "../../components/accordion/Accordion";
import Flex, {FlexItem} from "../../components/Flex";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";

const Division = () => {
    const {type, division} = useParams();
    const [currentRound, setCurrentRound] = useState(0);
    const [group, setGroup] = useState({rounds: [], matches: [], players: [], rules: ""});
    const [swissRound, setSwissRound] = useState<MatchProps[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isNextRoundSet, setIsNextRoundSet] = useState<boolean | null>(null);
    const [isSwissInit, setIsSwissInit] = useState<boolean | null>(null);
    const category = (division === "Jeunes") ? "U16" : "OPEN";

    useEffect(() => {
        fetchAPI(`/group/${type}/CdF2024/${division}`)
            .then(group => {
                if (group.players && group.players.length) {
                    fetchAPI(`/rounds/${type}/CdF2024/${category}/${division}`)
                        .then(round => {
                            if (round.matches && round.rounds) {
                                setGroup({
                                    rules: group.rules,
                                    players: group.players,
                                    matches: round.matches,
                                    rounds: round.rounds,
                                });
                            }
                            fetchAPI(`/current-round/CdF2024`)
                                .then(res => {
                                    setCurrentRound(res.current_round);
                                });
                        })
                        .catch(err => {
                            console.log(err);
                            alert(err);
                        });
                    if (group.rules === "swiss") {
                        fetchAPI(`/rounds/individual/CdF2024/${category}/${division}/next`)
                            .then(res => {
                                if (res && res.length) {
                                    setIsNextRoundSet(true);
                                    setSwissRound(res);
                                } else {
                                    setIsNextRoundSet(false);
                                }
                            });
                        fetchAPI(`/matches/individual/CdF2024/${category}/${division}`)
                            .then(res => {
                                if (res && res.length) {
                                    setIsSwissInit(true);
                                } else {
                                    setIsSwissInit(false);
                                }
                            });
                    }
                }
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
    }, [type, division, category]);

    const _generateSwissFirstRound = () => {
        if (group.rules === "swiss") {
            fetchAPI(`/matches/${type}/CdF2024/OPEN/${division}/${currentRound}/swiss/generate`)
                .then(response => {
                    if (response.errMsg) {
                        console.log(response.errMsg);
                    } else {
                        setSwissRound(response);
                        setShowModal(true);
                    }
                });
        }
    };

    const _generateNextSwissRound = () => {
        if (group.rules === "swiss") {
            fetchAPI(`/matches/${type}/CdF2024/OPEN/${division}/${currentRound}/swiss/generate`)
                .then(response => {
                    if (response.errMsg) {
                        console.log(response.errMsg);
                    } else {
                        setSwissRound(response);
                        setShowModal(true);
                    }
                });
        }
    };

    const _postNextRound = () => {
        fetchAPI(`/matches/CdF2024/individual/add`, "POST", {matches: swissRound})
            .then(res => {
                if (res.message && res.message === "OK") {
                    window.location.reload();
                }
                console.log(res);
            });
    };

    return (
        <DashboardLayout>
            <div className={`team-match-wrapper ${showModal ? "activated" : ""}`}>
                <div className={`team-match-container ${showModal ? "activated" : ""}`}>
                    <div className="team-match-container-header">
                        <img
                            src={require("../../assets/icons/close.png")}
                            alt="Close modal"
                            onClick={() => setShowModal(false)}
                        />
                    </div>
                    <Spacer/>
                    <div className="team-match-container-body">
                        <Container>
                            <Table rounded>
                                <TableHead>
                                    <tr>
                                        <td width="10%">Table</td>
                                        <td width="25%">Player 1</td>
                                        <td width="15%">Score</td>
                                        <td width="25%">Player 2</td>
                                        <td width="15%">Referee</td>
                                        <td width="10%">Category</td>
                                    </tr>
                                </TableHead>
                                <TableBody>
                                    {
                                        swissRound.map((match: MatchProps, index: number) => {
                                            return <tr key={index}>
                                                <td>{match.table_number}</td>
                                                <td>{`${match.player_one}`}</td>
                                                <td>{match.score_player_one} - {match.score_player_two}</td>
                                                <td>{`${match.player_two}`}</td>
                                                <td>{match.referee}</td>
                                                <td>{match.category}</td>
                                            </tr>;
                                        })
                                    }
                                </TableBody>
                            </Table>
                            {
                                !isNextRoundSet &&
                                <>
                                    <Spacer/>
                                    <Flex justifyContent="center">
                                        <FlexItem width="auto">
                                            <Button
                                                fontSize={`${Size.LEVEL_TWO}px`}
                                                padding="8px 16px"
                                                height={`auto`}
                                                onClick={_postNextRound}
                                            >
                                                Valider
                                            </Button>
                                        </FlexItem>
                                    </Flex>
                                </>
                            }
                        </Container>
                    </div>
                </div>
            </div>
            <Container>
                <Flex alignItems="flex-start" justifyContent="center">
                    <FlexItem>
                        <Title size={18} marginBottom={16}>
                            Type de tournoi :&nbsp;
                            <strong>{group.rules && (group.rules === "championship" ? "Championnat" : "Système Suisse")}</strong>
                        </Title>
                        <Title size={18} marginBottom={0}>
                            Série actuelle : <strong>{currentRound}</strong>
                        </Title>
                    </FlexItem>
                    {
                        (group.rules === "swiss") &&
                        <FlexItem>
                            {
                                (isSwissInit !== null && !isSwissInit) && <>
                                    <p>
                                        Pour générer la première série de la division <strong>{division}</strong>,
                                        c'est par ici :
                                    </p>
                                    <p>
                                        <Button
                                            fontSize={`${Size.LEVEL_TWO}px`}
                                            padding="8px 16px"
                                            height={`auto`}
                                            onClick={_generateSwissFirstRound}
                                        >
                                            Initialiser
                                        </Button>
                                    </p>
                                </>
                            }
                            {
                                (isSwissInit && isNextRoundSet !== null && !isNextRoundSet) && <>
                                    <p>
                                        Pour générer les matches de la série suivante ({currentRound + 1}), ça se
                                        passe ici :
                                    </p>
                                    <p>
                                        <Button
                                            fontSize={`${Size.LEVEL_TWO}px`}
                                            padding="8px 16px"
                                            height={`auto`}
                                            onClick={_generateNextSwissRound}
                                        >
                                            Générer
                                        </Button>
                                    </p>
                                </>
                            }
                            {
                                (isSwissInit && isNextRoundSet !== null && isNextRoundSet) && <>
                                    <p>
                                        La série suivante a déjà été générée.
                                    </p>
                                    <p>
                                        <Button
                                            fontSize={`${Size.LEVEL_TWO}px`}
                                            padding="8px 16px"
                                            height={`auto`}
                                            onClick={() => setShowModal(true)}
                                        >
                                            Voir la série
                                        </Button>
                                    </p>
                                </>
                            }
                        </FlexItem>
                    }
                </Flex>
            </Container>
            <Container>
                <Title>Classement</Title>
                <Table rounded>
                    <TableHead>
                        <tr>
                            <td style={{minWidth: 175}}
                                colSpan={2}>
                                <Title size={Size.LEVEL_TWO}
                                       marginBottom={0}>
                                    Joueur / joueuse
                                </Title>
                            </td>
                            <td>Pts</td>
                            <td>P</td>
                            <td>W</td>
                            <td>D</td>
                            <td>L</td>
                            <td>GF</td>
                            <td>GA</td>
                            <td>GD</td>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {
                            group.players.map((player: any, index: number) => {
                                return <tr key={index}>
                                    <td style={{width: "50px"}}>{index + 1}.</td>
                                    <td>{player.playerName}</td>
                                    <td style={{fontWeight: "bold"}}>{player.playerPoints}</td>
                                    <td>{player.wins + player.draws + player.loses}</td>
                                    <td>{player.wins}</td>
                                    <td>{player.draws}</td>
                                    <td>{player.loses}</td>
                                    <td>{player.gf}</td>
                                    <td>{player.ga}</td>
                                    <td>{player.gd}</td>
                                </tr>;
                            })
                        }
                    </TableBody>
                </Table>
            </Container>
            <Container>
                <Title>Matches</Title>
                <Accordion>
                    {
                        group.rounds.map((round: number, index: number) => {
                            return <AccordionItem key={index}>
                                <AccordionHeader>{`Série ${round}`}</AccordionHeader>
                                <AccordionBody>
                                    <Table>
                                        <TableHead>
                                            <tr>
                                                <td width="10%">Table</td>
                                                <td width="25%">Player 1</td>
                                                <td width="15%">Score</td>
                                                <td width="25%">Player 2</td>
                                                <td width="15%">Referee</td>
                                                <td width="10%">Category</td>
                                            </tr>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                group.matches
                                                    .filter((match: any) => match.round === round)
                                                    .map((match: MatchProps, index: number) => {
                                                        return <tr key={index}>
                                                            <td>{match.table_number}</td>
                                                            <td>{`${match.player_one}`}</td>
                                                            <td>{match.score_player_one} - {match.score_player_two}</td>
                                                            <td>{`${match.player_two}`}</td>
                                                            <td>{match.referee}</td>
                                                            <td>{match.category}</td>
                                                        </tr>;
                                                    })
                                            }
                                        </TableBody>
                                    </Table>
                                </AccordionBody>
                            </AccordionItem>;
                        })
                    }
                </Accordion>
            </Container>
        </DashboardLayout>
    );
};

export default Division;
