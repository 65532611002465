import React from "react";
import styled from "styled-components";

export interface AccordionHeaderProps {
    children?: string;
    onClick?: any;
}

const AccordionHeaderStyled = styled.div<AccordionHeaderProps>`
    padding: 16px;
    position: relative;
    cursor: pointer;
    font-size: 16px;

    &:after {
        position: absolute;
        content: "ᐅ";
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const AccordionHeader = ({children, onClick}: AccordionHeaderProps) => {
    return <AccordionHeaderStyled data-accordion-header onClick={onClick}>{children}</AccordionHeaderStyled>;
};

export default AccordionHeader;
