import React from 'react';
import styled from 'styled-components';
import {Size} from "../props/common";

interface ContainerProps {
    children?: any
    width?: string
    maxWidth?: string
    height?: string
    padding?: Size
    backgroundColor?: string
}

const ContainerStyled = styled.div<ContainerProps>`
  width: ${props => props.width};
  max-width: ${props => props.maxWidth ? props.maxWidth : '1366px'};
  height: ${props => props.height};
  padding: ${props => props.padding}px;
  background-color: ${props => props.backgroundColor};
  margin: auto;
`;

export const Container = ({
                              children,
                              width = "100%",
                              height = "auto",
                              padding = Size.LEVEL_TWO,
                              backgroundColor = "transparent",
                              maxWidth
                          }: ContainerProps) => {
    return <ContainerStyled width={width} height={height} padding={padding} backgroundColor={backgroundColor}
                            maxWidth={maxWidth}>
        {children}
    </ContainerStyled>
}

export default Container