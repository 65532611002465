import React, {useEffect, useState} from 'react';
import SingleMatch from "./SingleMatch";
import AllMatches from "./AllMatches";

const ViewMatchesTeam = () => {
    const [queryParams, setQueryParams] = useState<any>({});
    useEffect(() => {
        setQueryParams(Object.fromEntries(new URLSearchParams(window.location.search).entries()));
    }, []);

    return (
        Object.keys(queryParams).length ? <SingleMatch queryParams={queryParams}/> : <AllMatches/>
    )
}

export default ViewMatchesTeam;