import React from 'react';
import styled from 'styled-components';
import {Size} from "../props/common";

interface TitlePros {
    children?: any
    size?: Size
    marginBottom?: Size
    fontFamily?: "InterFont" | "Fredoka_OneFont"
    centered?: boolean
}

const TitleStyled = styled.p<TitlePros>`
  font-size: ${props => props.size}px;
  margin-bottom: ${props => props.marginBottom}px;
  position: relative;
  font-family: ${props => props.fontFamily};
  text-align: ${props => props.centered ? 'center' : 'left'};
`;

const Title = ({size = Size.LEVEL_THREE, children, marginBottom = Size.LEVEL_THREE, fontFamily = "InterFont", centered = false}: TitlePros) => {
    return <TitleStyled size={size} marginBottom={marginBottom} fontFamily={fontFamily} centered={centered}>
        {children}
    </TitleStyled>
}

export default Title;