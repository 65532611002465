import React from "react";
import styled from "styled-components";
import Title from "./Title";
import Flex, {FlexItem} from "./Flex";
import {grey20, grey30, red} from "../config/style";
import {Size} from "../props/common";
import Switch from "./Switch";
import {useNavigate} from "react-router-dom";
import Container from "./Container";

const NavbarStyled = styled.div`
    background-color: #fff;
    width: 100vw;
    box-shadow: 0 0 10px 0 ${grey20};
    z-index: 999;
    margin: -24px;
    margin-bottom: 0;

    #nav-sup-menu, #nav-sub-menu {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
    }

    #nav-sup-menu {
        width: 100%;
        height: 75px;
        padding: 0 24px;
        background-color: white;

        span {
            font-size: 16px;
            padding: 4px 0;

            &.is-active {
                border-bottom: 2px solid ${red};
            }
        }
    }

    #nav-sub-menu {
        width: 100%;
        height: 48px;
        background-color: white;
        border-top: 1px solid ${grey20};
    }

    @media screen and (max-width: 1024px) {
        margin: 0;

        #nav-skore-logo {
            display: none;
        }

        [data-flex] {
            justify-content: center;
        }
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        overflow: auto;

        @media screen and (max-width: 1024px) {
            justify-content: flex-start;
        }

        li {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;

            &:first-child {
                padding-left: 16px;
            }

            &:last-child {
                padding-right: 16px;
            }

            span {
                text-align: center;
                min-width: 56px;
                color: ${grey30};
            }

            a {
                width: auto;
                height: 100%;
                text-decoration: none;
                color: black;
                display: flex;
                align-items: center;
                font-size: 18px;
                border: 2px solid transparent;

                &:hover,
                &.is-active {
                    font-weight: bold;
                    border-bottom: 2px solid ${red};
                    color: ${red};
                }
            }
        }
    }
`;

export const Navbar = () => {
    const page = window.location.pathname.includes("individual") ? "individual" : "team";
    const division = window.location.pathname.split("/group/individual/")[1] || "";
    const navigate = useNavigate();

    const onSwitch = () => {
        setTimeout(() => navigate(`/dashboard/${page === "team" ? "individual" : "team"}`), 200);
    };

    return <NavbarStyled>
        <div id="nav-sup-menu">
            <Container padding={8}>
                <Flex alignItems="center" justifyContent="center" height="100%">
                    <FlexItem marginTop={0} id="nav-skore-logo">
                        <Title fontFamily="Fredoka_OneFont" size={Size.LEVEL_FIVE} marginBottom={0}>
                            skore.
                        </Title>
                    </FlexItem>
                    <FlexItem marginTop={0} width="auto" minWidth="400px">
                        <Flex justifyContent="flex-end" mobile>
                            <FlexItem marginTop={0} width="auto" minWidth="none">
                                <Title marginBottom={0} centered>
                                    <span className={page === "individual" ? "is-active" : ""}>Individuel</span>
                                </Title>
                            </FlexItem>
                            <FlexItem width="auto" minWidth="none"/>
                            <FlexItem marginTop={0} width="auto" minWidth="70px">
                                <Switch active={page === "team"} onChange={onSwitch}/>
                            </FlexItem>
                            <FlexItem width="auto" minWidth="none"/>
                            <FlexItem marginTop={0} width="auto" minWidth="none">
                                <Title marginBottom={0} centered>
                                    <span className={page === "team" ? "is-active" : ""}>Équipes</span>
                                </Title>
                            </FlexItem>
                        </Flex>
                    </FlexItem>
                </Flex>
            </Container>
        </div>
        <div id="nav-sub-menu">
            <Container padding={0} height="100%" maxWidth="none">
                <ul>
                    {
                        page === "individual" && <>
                            <li>
                                <a href={"/group/individual/D1"} className={division === "D1" ? "is-active" : ""}>
                                    D1
                                </a>
                            </li>
                            <li><span>|</span></li>
                            <li>
                                <a href={"/group/individual/D2"} className={division === "D2" ? "is-active" : ""}>
                                    D2
                                </a>
                            </li>
                            <li><span>|</span></li>
                            <li>
                                <a href={"/group/individual/D3"} className={division === "D3" ? "is-active" : ""}>
                                    D3
                                </a>
                            </li>
                            <li><span>|</span></li>
                            <li>
                                <a href={"/group/individual/D4"} className={division === "D4" ? "is-active" : ""}>
                                    D4
                                </a>
                            </li>
                            <li><span>|</span></li>
                            <li>
                                <a href={"/group/individual/Subbuteo"}
                                   className={division === "Subbuteo" ? "is-active" : ""}>
                                    Subbuteo
                                </a>
                            </li>
                            <li><span>|</span></li>
                            <li>
                                <a href={"/group/individual/Jeunes"}
                                   className={division === "Jeunes" ? "is-active" : ""}>
                                    Jeunes
                                </a>
                            </li>
                        </>
                    }
                    {
                        page === "team" && <>
                            <li>D1</li>
                        </>
                    }
                </ul>
            </Container>
        </div>
    </NavbarStyled>;
};

export default Navbar;
