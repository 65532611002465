import React, {useEffect, useState} from "react";
import DashboardLayout from "../../components/DashboardLayout";
import {fetchAPI} from "../../config/fetch";
import Table from "../../components/table/Table";
import TableHead from "../../components/table/TableHead";
import TableBody from "../../components/table/TableBody";
import Accordion from "../../components/accordion/Accordion";
import AccordionItem from "../../components/accordion/AccordionItem";
import AccordionHeader from "../../components/accordion/AccordionHeader";
import AccordionBody from "../../components/accordion/AccordionBody";
import Container from "../../components/Container";
import {CategoriesProps, CategoryProps, GroupProps, Size} from "../../props/common";
import Spacer from "../../components/Spacer";
import Title from "../../components/Title";
import Nav from "../../components/Nav";

interface PageProps {
    withoutHead?: boolean;
}

const IndividualGroups = ({withoutHead = false}: PageProps) => {
    const [categories, setCategories] = useState<CategoriesProps | any>({
        open: {},
        u12: {},
        u16: {},
        u20: {}
    });

    useEffect(() => {
        fetchAPI("/groups/individual/CdF2024")
            .then(res => {
                if (Object.keys(res).length) {
                    setCategories({
                        open: {
                            name: "OPEN",
                            groups: res.filter((group: GroupProps) => group.category === "OPEN")?.sort((a: GroupProps, b: GroupProps) => a.group.localeCompare(b.group)),
                        },
                        u20: {
                            name: "U20",
                            groups: res.filter((group: GroupProps) => group.category === "U20")
                        },
                        u16: {
                            name: "U16",
                            groups: res.filter((group: GroupProps) => group.category === "U16")
                        },
                        u12: {
                            name: "U12",
                            groups: res.filter((group: GroupProps) => group.category === "U12")
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
    }, []);

    return (
        <DashboardLayout navbar={false}>
            {
                <Container padding={Size.LEVEL_TWO} maxWidth="1366px">
                    {
                        !withoutHead && <Nav currentPage="groups" parent="individual"/>
                    }
                    {Object.keys(categories).length &&
                        <>
                            {
                                !withoutHead && <Spacer size={Size.LEVEL_FOUR}/>
                            }
                            {
                                Object.keys(categories).map((item: string, index: number) => {
                                    const category: CategoryProps = categories[item];
                                    return <div key={index}>
                                        {
                                            index > 0 && <Spacer size={Size.LEVEL_FOUR}/>
                                        }
                                        <Title size={20} key={index}>{category.name}</Title>
                                        <Accordion>
                                            {
                                                category?.groups?.map((group: GroupProps, index: number) => {
                                                    return <AccordionItem
                                                        key={index}
                                                        className={index === 0 ? "is-active" : ""}
                                                    >
                                                        <AccordionHeader>{group.group}</AccordionHeader>
                                                        <AccordionBody>
                                                            <Table>
                                                                <TableHead>
                                                                    <tr>
                                                                        <td style={{minWidth: 175}}
                                                                            colSpan={2}>
                                                                            <Title size={Size.LEVEL_TWO}
                                                                                   marginBottom={0}>
                                                                                Joueur / joueuse
                                                                            </Title>
                                                                        </td>
                                                                        <td>Pts</td>
                                                                        <td>P</td>
                                                                        <td>W</td>
                                                                        <td>D</td>
                                                                        <td>L</td>
                                                                        <td>GF</td>
                                                                        <td>GA</td>
                                                                        <td>GD</td>
                                                                    </tr>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        group.players.map((player: any, index: number) => {
                                                                            return <tr key={index}>
                                                                                <td style={{width: "50px"}}>{index + 1}.</td>
                                                                                <td>{player.playerName}</td>
                                                                                <td style={{fontWeight: "bold"}}>{player.playerPoints}</td>
                                                                                <td>{player.wins + player.draws + player.loses}</td>
                                                                                <td>{player.wins}</td>
                                                                                <td>{player.draws}</td>
                                                                                <td>{player.loses}</td>
                                                                                <td>{player.gf}</td>
                                                                                <td>{player.ga}</td>
                                                                                <td>{player.gd}</td>
                                                                            </tr>;
                                                                        })
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </AccordionBody>
                                                    </AccordionItem>;
                                                })
                                            }
                                        </Accordion>
                                    </div>;
                                })
                            }
                        </>
                    }
                </Container>
            }
        </DashboardLayout>
    );
};

export default IndividualGroups;
