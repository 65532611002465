import React from "react";
import styled from "styled-components";
import {TableHeadPros} from "./TableHead";
import {TableBodyProps} from "./TableBody";
import {red} from "../../config/style";

interface TablePros {
    children?: React.ReactElement<TableHeadPros & TableBodyProps>[] | any;
    rounded?: boolean;
}

const TableStyled = styled.table<TablePros>`
    width: 100%;
    border-collapse: collapse;

    td {
        padding: 16px;
    }
`;

const Table = ({children, rounded = false}: TablePros) => {
    return (
        <div style={{
            border: rounded ? `2px solid ${red}` : "unset",
            borderRadius: rounded ? "8px" : "0",
            overflow: "auto"
        }}>
            <TableStyled>
                {children}
            </TableStyled>
        </div>);
};

export default Table;