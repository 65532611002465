export enum CountriesEnum {
    FRANCE = "FRANCE",
    BELGIQUE = "BELGIQUE"
}

export interface PlayerProps {
    firstname: string;
    lastname: string;
    fullname: string;
    birthdate: string;
    club: string;
    country: CountriesEnum | string;
    star: number;
}

export interface TeamProps {
    name: string,
    all_players: PlayerProps[],
    country?: CountriesEnum
}

export interface MatchProps {
    id: number;
    player_one: PlayerProps;
    player_two: PlayerProps;
    referee: string;
    round: number;
    score_player_one: number;
    score_player_two: number;
    table_number: number;
    category: string;
    group?: string;
    team?: boolean;
    is_frozen?: boolean;
    substitute_player_one?: string | null;
    substitute_player_two?: string | null;
}

export interface TeamMatchProps extends MatchProps {
    score_team_one: number;
    score_team_two: number;
    team_one: TeamProps;
    team_two: TeamProps;
    all_single_matches: MatchProps[];
}

export const emptyMatch = {
    category: "",
    id: -1,
    player_one: {
        firstname: "",
        lastname: "",
        fullname: "",
        birthdate: "",
        club: "",
        country: "",
        star: 0
    },
    player_two: {
        firstname: "",
        lastname: "",
        fullname: "",
        birthdate: "",
        club: "",
        country: "",
        star: 0
    },
    referee: "",
    round: 0,
    score_player_one: 0,
    score_player_two: 0,
    table_number: 0,
    team: false,
    is_frozen: false
};

export const teamEmptyMatch = {
    ...emptyMatch,
    all_single_matches: [],
    team_one: {
        name: "",
        all_players: []
    },
    team_two: {
        name: "",
        all_players: []
    },
    score_team_one: 0,
    score_team_two: 0,
    is_frozen: false
};

export interface PlayerProps {
    firstname: string;
    lastname: string;
}

export const _sortMatchesByTableNumber = (array: MatchProps[]) => {
    return array.sort((a, b) => (a.table_number > b.table_number) ? 1 : ((b.table_number > a.table_number) ? -1 : 0));
};
