export const yellow = '#FAC213';
export const lightYellow = '#ffedc8';
export const orange = '#F77E21';
export const lightOrange = '#ffd0ac';
export const red = '#2D9596';
export const lightRed = '#e4f1f1';
export const green = '#36AE7C';
export const lightGreen = '#c0ffe2';
export const grey10 = '#f5f5f5';
export const grey20 = '#ededed';
export const grey30 = '#d9d9d9';
export const grey50 = '#c4c4c4';
export const grey75 = '#9b9b9b';
export const grey = '#6e6e6e';
export const grey150 = '#383838';