import React from 'react';
import styled from 'styled-components';
import {AccordionItemProps} from "./AccordionItem";

interface AccordionProps {
    children: React.ReactElement<AccordionItemProps> | React.ReactElement<AccordionItemProps>[]
}

const AccordionStyled = styled.div<AccordionProps>`
`;

export const Accordion = ({children}: AccordionProps) => {
    return <AccordionStyled>
        {children}
    </AccordionStyled>
}

export default Accordion
