import React from 'react';
import styled from 'styled-components';
import Navbar from "./Navbar";

interface DashboardLayoutProps {
    children?: any
    navbar?: boolean
}

const DashboardLayoutStyled = styled.div<DashboardLayoutProps>`
`;

export const DashboardLayout = ({children, navbar = true}: DashboardLayoutProps) => {
    return <DashboardLayoutStyled navbar={navbar}>
        {
            navbar && <Navbar/>
        }
        {children}
    </DashboardLayoutStyled>
}

export default DashboardLayout