import Flex from "./Flex";

interface SwitchProps {
    onChange?: () => void;
    active?: boolean;
}

const Switch = ({onChange, active = false}: SwitchProps) => {
    return <Flex alignItems="center">
        <input data-switch="true" type="checkbox" id="switch" onChange={onChange} defaultChecked={active}/>
        <label data-switch="true" htmlFor="switch">Toggle</label>
    </Flex>;
};

export default Switch;