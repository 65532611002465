import React from "react";
import styled from "styled-components";
import {grey10, red} from "../config/style";
import {Size} from "../props/common";

interface InputProps {
    placeholder?: string;
    defaultValue?: string | number;
    onChange?: (event: any) => void;
    marginLess?: boolean;
    type?: "text" | "number" | "password";
    min?: number;
    max?: number;
}

interface InputFileProps {
    onChange?: any;
}

const InputStyled = styled.input<InputProps>`
    background-color: ${grey10};
    border-radius: 8px;
    padding: ${Size.LEVEL_THREE}px;
    margin-bottom: ${props => props.marginLess ? "0" : `${Size.LEVEL_TWO}`}px;
    width: 100%;
    height: ${Size.LEVEL_FIVE}px;
    box-sizing: border-box;
    color: ${red};
    font-weight: bold;
    font-size: 18px;
    box-shadow: 0 0 10px 0 #e6e6e6;
    outline-color: ${red};

    &::placeholder {
        color: ${red};
    }
`;

const InputFileStyled = styled.input`
`;

export const Input = ({
                          placeholder = "",
                          defaultValue = "",
                          onChange,
                          marginLess,
                          type = "text",
                          min,
                          max
                      }: InputProps) => {
    switch (type) {
        case "text":
            return <InputStyled
                type="text"
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                marginLess={marginLess}
            />;
        case "number":
            if ((min !== undefined && min >= 0) && max) {
                return <InputStyled
                    type="number"
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    marginLess={marginLess}
                    min={min}
                    max={max}
                />;
            } else {
                return <InputStyled
                    type="number"
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    marginLess={marginLess}
                />;
            }
        case "password":
            return <InputStyled
                type="password"
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                marginLess={marginLess}
            />;
        default:
            return <InputStyled
                type="text"
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                marginLess={marginLess}
            />;
    }
};

export const InputFile = ({onChange}: InputFileProps) => {
    return <InputFileStyled type="file" onChange={onChange}/>;
};

export default Input;
