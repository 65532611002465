import React from 'react';
import Input from "../components/Input";
import Title from "../components/Title";
import {Size} from "../props/common";
import Container from "../components/Container";
import {red} from "../config/style";
import Flex from "../components/Flex";
import Button from "../components/Button";

const Auth = () => {
    return (
        <Flex>
            <Container width="35vw" height="100vh" padding={Size.LEVEL_FIVE}>
                <Flex height="100%" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                    <Title size={Size.LEVEL_FIVE}>Authentication</Title>
                    <form>
                        <Input placeholder="Your email address"/>
                        <Input placeholder="Your password"/>
                    </form>
                    <Button>Log In</Button>
                </Flex>
            </Container>
            <Container height="100vh" backgroundColor={red}/>
        </Flex>
    )
}

export default Auth;